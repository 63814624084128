import React, { useRef, useEffect, useState } from 'react';
import { useLottie } from "lottie-react";
import groovyWalkAnimation from "./CarAnimation.json";
import "../Styles/HomePage.css";
import "../Styles/scroll2.css";
import { Container, Row, Col } from 'react-bootstrap';
import Letstalk from './Lets talk';

const SheSquad = () => {
  const options = {
      animationData: groovyWalkAnimation,
      loop: true
    };

    const { View } = useLottie(options);
  // const firstSectionRef = useRef(null);
  // const secondSectionRef = useRef(null);
  // const thirdSectionRef = useRef(null);
  // const fourthSectionRef = useRef(null);
  // const fifthSectionRef = useRef(null);
  // const [isFirstSectionVisible, setIsFirstSectionVisible] = useState(true);
  // const [isSecondSectionVisible, setIsSecondSectionVisible] = useState(true);
  // const [isThirdSectionVisible, setIsThirdSectionVisible] = useState(true);
  // const [isFourthSectionVisible, setIsFourthSectionVisible] = useState(true);
  // const [isFifthSectionVisible, setIsFifthSectionVisible] = useState(true);
  // const [backgroundColor, setBackgroundColor] = useState('#0d0d0d');

  // Function to handle scroll behavior
  // const handleScroll = () => {
  //   if (firstSectionRef.current && secondSectionRef.current && thirdSectionRef.current && fourthSectionRef.current && fifthSectionRef.current) {
  //     const scrollPosition = window.scrollY;
  //     const firstSectionOffset = firstSectionRef.current.offsetTop;
  //     const secondSectionOffset = secondSectionRef.current.offsetTop;
  //     const thirdSectionOffset = thirdSectionRef.current.offsetTop;
  //     const fourthSectionOffset = fourthSectionRef.current.offsetTop;
  //     const fifthSectionOffset = fifthSectionRef.current.offsetTop;

      

  //     if (scrollPosition >= firstSectionOffset && scrollPosition < secondSectionOffset) {
  //       setBackgroundColor('#2A2E3F');
  //       setIsFirstSectionVisible(true);
  //       setIsSecondSectionVisible(true);
  //       setIsThirdSectionVisible(true);
  //       setIsFourthSectionVisible(true);
  //       setIsFifthSectionVisible(true);
  //     } else if (scrollPosition >= secondSectionOffset && scrollPosition < thirdSectionOffset) {
  //       setIsFirstSectionVisible(false);
  //       setIsSecondSectionVisible(true);
  //       setIsThirdSectionVisible(true);
  //       setIsFourthSectionVisible(true);
  //       setIsFifthSectionVisible(true);
  //     } else if (scrollPosition >= thirdSectionOffset && scrollPosition < fourthSectionOffset) {
  //       setIsFirstSectionVisible(false);
  //       setIsSecondSectionVisible(false);
  //       setIsThirdSectionVisible(true);
  //       setIsFourthSectionVisible(true);
  //       setIsFifthSectionVisible(true);
  //     } else if (scrollPosition >= fourthSectionOffset && scrollPosition < fifthSectionOffset) {
  //       setIsFirstSectionVisible(false);
  //       setIsSecondSectionVisible(false);
  //       setIsThirdSectionVisible(false);
  //       setIsFourthSectionVisible(true);
  //       setIsFifthSectionVisible(true);
  //     } else {
  //       setIsFirstSectionVisible(false);
  //       setIsSecondSectionVisible(false);
  //       setIsThirdSectionVisible(false);
  //       setIsFourthSectionVisible(false);
  //       setIsFifthSectionVisible(true);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  return (
    // <div className='app2 mt-5' style={{backgroundColor:"#0d0d0d"}}>
    //   <div  className={`first-section ${isFirstSectionVisible ? '' : 'hidden'} container`} >
        
    //   <Row className='d-flex service5'style={{   overflowX: "auto", padding: "10px" }} >
    // <Col xs={6} style={{  flexDirection: "column" }} className='d-flex '>
    //   <div className='fixed'>
    //     <div>
    //   <div class="relative" style={{ translate: "none", rotate: "none", scale: "none", transform: "translate(0px, 0px)" }}>
    //     <h1 style={{ fontSize: '120px', color: '#74D077' }}>Daily <br></br>Car Wash</h1>
    //   </div>
    //   </div>
    //   <div className='mt-4'>
    //       <Letstalk
    //       value="Book Now" />
    //       </div>
    //       </div>
    //       </Col>
    //   <Col xs={6}   >
    //     <div >
    //   <Row className='mt-4'>
    //     <div className='d-flex justify-content-start flex-start'>
    //         <img src="./images/Dailycleaning2.png" style={{width:"40%"}}/>
    //     </div>
    //  </Row>
    //   <div >
    //     <div className='d-flex mt-4' style={{ flexDirection: "row" }}>
    //     <img src="./images/bullet-rightmark.png" style={{width:"20px",height:"20px"}} className='d-flex justify-content-center align-items-center mt-3'/>
    //     <h1 style={{color:"#B7AA98",fontSize:"26px",lineHeight:1.4}} className='ms-4 daily-car-wash-desc' >The daily external vehicle cleaning happens at your parking lot only</h1>
    //   </div>
    //   <div className='d-flex mt-4' style={{flexDirection:"row"}}>
    //     <img src="./images/bullet-rightmark.png" style={{width:"20px",height:"20px"}} className='d-flex justify-content-center align-items-center mt-3'/>
    //     <h1 style={{color:"#B7AA98",fontSize:"26px",lineHeight:1.4}} className='ms-4 daily-car-wash-desc' >The service timings are from 6am - 9am</h1>
    //   </div>
    //   <div className='d-flex mt-4' style={{flexDirection:"row"}}>
    //     <img src="./images/bullet-rightmark.png" style={{width:"20px",height:"20px"}} className='d-flex justify-content-center align-items-center mt-3'/>
    //     <h1 style={{color:"#B7AA98",fontSize:"26px",lineHeight:1.4}} className='ms-4 daily-car-wash-desc' >Unlimited Free Car Spa is included in this package</h1>
    //   </div>
    //   <div className='d-flex mt-4' style={{flexDirection:"row"}}>
    //     <img src="./images/bullet-rightmark.png" style={{width:"20px",height:"20px"}} className='d-flex justify-content-center align-items-center mt-3'/>
    //     <h1 style={{color:"#B7AA98",fontSize:"26px",lineHeight:1.4}} className='ms-4 daily-car-wash-desc mt-1' >Weekly one holiday</h1>
    //   </div>
     
      
      
    //  </div> 
    //  </div>
    //  </Col>
    //   </Row>
    //     {/* Content of the first section */}
    //   </div>

    //   <div ref={secondSectionRef} className={`second-section ${isSecondSectionVisible ? '' : 'hidden'} container-fluid`}>
    //     <div className='container'>
    //   <Row className='d-flex service5 ' style={{   overflowX: "auto", padding: "10px" }} >
    // <Col xs={6} style={{  flexDirection: "column",position:"relative" }} className='d-flex '>
    //   <div  >
    //     <div > 
    //   <div class="relative" style={{ translate: "none", rotate: "none", scale: "none", transform: "translate(0px, 0px)" }}>
    //     <h1 style={{ fontSize: '120px', color: '#74D077' }}>Daily <br></br>Car Wash</h1>
    //   </div>
    //   </div>
    //   <div className='mt-3'>
    //       <Letstalk
    //       value="Book Now" />
    //       </div>
    //       </div>
    //       </Col>
    //   <Col xs={6}  >
    //   <div >
    //     <div className='d-flex' style={{ flexDirection: "row" }}>
    //     <img src="./images/bullet-rightmark.png" style={{width:"20px",height:"20px"}} className='d-flex justify-content-center align-items-center mt-3'/>
    //     <h1 style={{color:"#B7AA98",fontSize:"26px",lineHeight:1.4}} className='ms-4 daily-car-wash-desc' >The daily external vehicle cleaning happens at your parking lot only</h1>
    //   </div>
    //   <div className='d-flex mt-4' style={{flexDirection:"row"}}>
    //     <img src="./images/bullet-rightmark.png" style={{width:"20px",height:"20px"}} className='d-flex justify-content-center align-items-center mt-3'/>
    //     <h1 style={{color:"#B7AA98",fontSize:"26px",lineHeight:1.4}} className='ms-4 daily-car-wash-desc' >The service timings are from 6am - 9am</h1>
    //   </div>
    //   <div className='d-flex mt-4' style={{flexDirection:"row"}}>
    //     <img src="./images/bullet-rightmark.png" style={{width:"26px",height:"26px"}} className='d-flex justify-content-center align-items-center mt-3'/>
    //     <h1 style={{color:"#B7AA98",fontSize:"26px",lineHeight:1.4}} className='ms-4 daily-car-wash-desc' >Unlimited Free Car Spa is included in this package</h1>
    //   </div>
      
      
      
    //  </div> 
    //  <Row>
    //     <div className='d-flex justify-content-start flex-start'>
    //         <img src="./images/Dailycleaning2.png" style={{width:"40%"}}/>
    //     </div>
    //  </Row>
    //  </Col>
    //   </Row>
    //   </div>
    //     {/* Content of the second section */}
    //   </div>

    //   <div ref={thirdSectionRef} className="third-section">
    //   <div className='container'>
    //   <Row className='d-flex service5 ' style={{   overflowX: "auto", padding: "10px" }} >
    // <Col xs={6} style={{  flexDirection: "column",position:"relative" }} className='d-flex '>
    //   <div  >
    //     <div > 
    //   <div class="relative" style={{ translate: "none", rotate: "none", scale: "none", transform: "translate(0px, 0px)" }}>
    //     <h1 style={{ fontSize: '120px', color: '#74D077' }}>Daily <br></br>Car Wash</h1>
    //   </div>
    //   </div>
    //   <div className='mt-3'>
    //       <Letstalk
    //       value="Book Now" />
    //       </div>
    //       </div>
    //       </Col>
    //   <Col xs={6}  >
    //   <div >
    //     <div className='d-flex' style={{ flexDirection: "row" }}>
    //     <img src="./images/bullet-rightmark.png" style={{width:"20px",height:"20px"}} className='d-flex justify-content-center align-items-center mt-3'/>
    //     <h1 style={{color:"#B7AA98",fontSize:"26px",lineHeight:1.4}} className='ms-4 daily-car-wash-desc' >The daily external vehicle cleaning happens at your parking lot only</h1>
    //   </div>
    //   <div className='d-flex mt-4' style={{flexDirection:"row"}}>
    //     <img src="./images/bullet-rightmark.png" style={{width:"20px",height:"20px"}} className='d-flex justify-content-center align-items-center mt-3'/>
    //     <h1 style={{color:"#B7AA98",fontSize:"26px",lineHeight:1.4}} className='ms-4 daily-car-wash-desc' >The service timings are from 6am - 9am</h1>
    //   </div>
    //   <div className='d-flex mt-4' style={{flexDirection:"row"}}>
    //     <img src="./images/bullet-rightmark.png" style={{width:"26px",height:"26px"}} className='d-flex justify-content-center align-items-center mt-3'/>
    //     <h1 style={{color:"#B7AA98",fontSize:"26px",lineHeight:1.4}} className='ms-4 daily-car-wash-desc' >Unlimited Free Car Spa is included in this package</h1>
    //   </div>
      
      
      
    //  </div> 
    //  <Row>
    //     <div className='d-flex justify-content-start flex-start'>
    //         <img src="./images/Dailycleaning2.png" style={{width:"40%"}}/>
    //     </div>
    //  </Row>
    //  </Col>
    //   </Row>
    //   </div>
    //     {/* Content of the third section */}
    //   </div>
    //   <div ref={fourthSectionRef} className={`fourth-section ${isFourthSectionVisible ? '' : 'hidden'}`}>
    //     <h2>A car, or an automobile, is a motor vehicle with wheels. Most definitions of cars state that they run primarily on roads, seat one to eight people, have four wheels, and mainly transport people, not cargo.[1][2] French inventor Nicolas-Joseph Cugnot built the first steam-powered road vehicle in 1769, while French-born Swiss inventor François Isaac de Rivaz designed and constructed the first internal combustion-powered automobile in 1808.
    //     A car, or an automobile, is a motor vehicle with wheels. Most definitions of cars state that they run primarily on roads, seat one to eight people, have four wheels, and mainly transport people, not cargo.[1][2] French inventor Nicolas-Joseph Cugnot built the first steam-powered road vehicle in 1769, while French-born Swiss inventor François Isaac de Rivaz designed and constructed the first internal combustion-powered automobile in 1808.
    //     A car, or an automobile, is a motor vehicle with wheels. Most definitions of cars state that they run primarily on roads, seat one to eight people, have four wheels, and mainly transport people, not cargo.[1][2] French inventor Nicolas-Joseph Cugnot built the first steam-powered road vehicle in 1769, while French-born Swiss inventor François Isaac de Rivaz designed and constructed the first internal combustion-powered automobile in 1808.
    //     </h2>
    //     {/* Content of the fourth section */}
    //   </div>

    //   <div ref={fifthSectionRef} className={`fifth-section ${isFifthSectionVisible ? '' : 'hidden'}`}>
    //     <h2>A car, or an automobile, is a motor vehicle with wheels. Most definitions of cars state that they run primarily on roads, seat one to eight people, have four wheels, and mainly transport people, not cargo.[1][2] French inventor Nicolas-Joseph Cugnot built the first steam-powered road vehicle in 1769, while French-born Swiss inventor François Isaac de Rivaz designed and constructed the first internal combustion-powered automobile in 1808.
    //     A car, or an automobile, is a motor vehicle with wheels. Most definitions of cars state that they run primarily on roads, seat one to eight people, have four wheels, and mainly transport people, not cargo.[1][2] French inventor Nicolas-Joseph Cugnot built the first steam-powered road vehicle in 1769, while French-born Swiss inventor François Isaac de Rivaz designed and constructed the first internal combustion-powered automobile in 1808.
    //     A car, or an automobile, is a motor vehicle with wheels. Most definitions of cars state that they run primarily on roads, seat one to eight people, have four wheels, and mainly transport people, not cargo.[1][2] French inventor Nicolas-Joseph Cugnot built the first steam-powered road vehicle in 1769, while French-born Swiss inventor François Isaac de Rivaz designed and constructed the first internal combustion-powered automobile in 1808.
    //     A car, or an automobile, is a motor vehicle with wheels. Most definitions of cars state that they run primarily on roads, seat one to eight people, have four wheels, and mainly transport people, not cargo.[1][2] French inventor Nicolas-Joseph Cugnot built the first steam-powered road vehicle in 1769, while French-born Swiss inventor François Isaac de Rivaz designed and constructed the first internal combustion-powered automobile in 1808.
    //     </h2>
    //     {/* Content of the fifth section */}
    //   </div>
    // </div>
    <div style={{backgroundColor:"#0D0D0D",display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center"}}>
        <div style={{width:"40%"}} className='d-flex justify-content-center align-items-center'>
            {View}
        </div>
        <h1 style={{color:"white"}} className='assembling'>Hold on... We are assembling!</h1>
    </div>
  );
};

export default SheSquad;
