import React from 'react'
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import {  useNavigate } from 'react-router-dom';
import "../Styles/HomePage.css"


const TermsandConditions = () => {
    const button5=useNavigate()
    const navigate5=()=>
  {
    button5('/')
  }
   
  return (


    <section style={{backgroundColor:"#0D0D0D"}}>
        
        <Container fluid className="p-5">
        <img src="./images/backbutton.png" onClick={navigate5} alt="" className='arrow-img' ></img>
     
            <h1 style={{color:"white",marginTop:"2%"}} className='privacypolicy2'>Terms of use</h1>
            <h6 style={{marginTop:"30px",color:"white"}} className='policy-para'>By entering, connecting, using or creating an account on this website and its associated applications on iPhone, iPad, Android, and any mobile application (hereinafter collectively referred to as the "Platform") including without limitation to any services offered on the Platform, you acknowledge that you have read and understood the following Terms and Conditions (collectively the  "Terms"), including these Terms, terms of our Privacy Policy available at www.om.honc.io and you agree to be bound by them and to comply with all the applicable laws and regulations regarding your use of the Platform.  You acknowledge that these Terms constitute a binding and enforceable legal contract between yourself and Cultural Links Technology LLC (hereinafter referred to as "honc", "Company", "we", "us", "our") with reference to the use of this Platform. Please read these terms before ordering any items from our application.</h6>
<p style={{marginTop:"30px",color:"white"}}className='encourage' >We encourage you to read the Privacy Policy whenever using the honc platform or submitting any personal information to honc.</p>
<p style={{marginTop:"30px",color:"white"}}className='encourage'>You become a user of the Platform ("User" or "you"), by entering, using, browsing or registering on the Platform. As a User, you agree and undertake to comply with the provisions of these Terms.</p>
<p style={{marginTop:"30px",color:"white"}}className='encourage'>You are responsible for making all arrangements necessary for you to have access to the Platform. You are also responsible for ensuring that all persons who access the Platform through your Internet connection are aware of these Terms and that they comply with them. By viewing, using, accessing, browsing, or submitting any content or material on the Platform, you agree to these Terms as a binding legal agreement, without limitation or qualification. The term "you" or "You" shall include any person or entity, who views, uses, accesses, browses or submits any content or material to the Platform.<br></br>
If you do not agree to these Terms, then you may not use the Platform. honc reserves the right to modify these Terms at any time without prior notice. You agree that each visit you make to the Platform shall be subject to the Terms as displayed, and your continued use of the Platform now or following the modifications in these Terms confirms that you have read, accepted, and agreed to be bound by such modifications
</p>
<Accordion className='rounded-2' >
      <Accordion.Item eventKey="0" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header' style={{color:"white"}} >Content</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
            <div>

    <p className='collect1'>Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material ("Content"). You are responsible for the content posted. We reserve the right to remove any content from our site for any reason.</p>
    </div>
   
   
        </Accordion.Body>


      </Accordion.Item>
      <Accordion.Item eventKey="1" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'  style={{color:"white"}} >Pricing & payment terms</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p  className='collect1'>honc does not provide any payment service either to you or to third parties. All online payments are processed via your own issuer / payment service provider and the payment service providers in partnership with honc.</p>
    <p  className='collect1'>Prices quoted on the Platform shall be displayed in the applicable country's national currency and subject to applicable tax. You accept that prices and offers on the Platform may vary from the prices and offers offered by our services  elsewhere (including their own websites, mobile applications, or at their brick-and-mortar outlets). The way we display the prices of our services  may vary depending on the location, segment of vehicle,  and, subject to applicable laws, the prices reflected on our Platform. Prices and additional charges indicated on the Platform are as at the time of each booking and may be subject to change later. 
honc reserves the right to charge fees  (including but not limited to service fees)  for our services as we deem necessary for our business.  The breakdown of the prices and additional charges (including but not limited to tax or Service fees) are displayed before checkout. When you place an Order, you agree to all amounts, additional charges and the final ‘Total' amount which is displayed to you.
</p>

<h4 className='info1'>Payment Methods</h4>

<p  className='collect1'>When using your Account as payment instrument you may choose from different payment methods as available and indicated on the Platform.</p>
<p  className='collect1'>honc reserves the right to offer additional payment methods and/or remove existing payment methods at any time at its sole discretion. If you choose to pay using an online payment method, the payment shall be processed by our third party payment service provider(s). With your consent, your credit card / payment information will be stored with our third party payment service provider(s) for future Orders. honc does not store your credit card or payment information.</p>
<p  className='collect1'>You must ensure that you have sufficient funds on your credit and debit card to fulfill payment of a booking. honc takes responsibility for payments made on our Platform including refunds, chargebacks, cancellations and dispute resolution, provided if reasonable and justifiable and in accordance with these Terms</p>
<p className='collect1'>At the time of checkout, please read the terms and conditions carefully before using any payment method.</p>
        </Accordion.Body>


      </Accordion.Item>
      <Accordion.Item eventKey="2" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'  style={{color:"white"}} >Termination</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p className='collect1'>honc has the right to terminate, suspend or delete your Account and your access to or use of the Platform, including any delivery service we provide to you in respect of an Order, for any reason, including, without limitation, if honc, in its sole discretion, considers your use to be unacceptable, or in the event of any breach by you of these Terms. Where appropriate, honc will give you the opportunity to state your position, before any termination, suspension or deletion of your account.
    </p>
   
        </Accordion.Body>


      </Accordion.Item>
      <Accordion.Item eventKey="3" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className="intellectual property"  style={{color:"white"}} >Intellectual property</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p className='collect1'>All trademarks, logos, images, and service marks, including these Terms as displayed on the Platform or in our marketing material are the Intellectual Property of honc and/or third parties who have authorised us with the right to use such proprietary rights (collectively the "Intellectual Property"). You may not use, copy, reproduce, republish, upload, post, transmit, distribute, or modify these Intellectual Property in any way without our prior express written consent. The use of our Intellectual Property rights, including but not limited to the honc’s trademarks and copyrights to the honc logo on any other website not approved by us is strictly prohibited. honc will aggressively enforce its Intellectual Property rights to the fullest extent of the laws, including criminal prosecution. All rights are reserved. </p>
        </Accordion.Body>


      </Accordion.Item>
      <Accordion.Item eventKey="4" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'style={{color:"white"}}>Applicable law</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p className='collect1'>These Terms shall be governed by and construed in accordance with the laws of the Sultanate of Oman. Disputes arising in connection with these Platform Terms (including non-contractual disputes) shall be subject to the exclusive jurisdiction of the courts of the Sultanate of Oman..</p>
        </Accordion.Body>


      </Accordion.Item>

      <Accordion.Item eventKey="5" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'  style={{color:"white"}} >Refund policy</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p className='collect1'>At honc, we aim to provide a satisfactory experience to all our customers who use our services. Service cancellation refund will be processed to the customer through their respective mode of payment of the service within 48hrs of cancellation request received.
    Please review the following policy regarding refunds:</p>
    <p className='collect1'>honc shall not be liable for any delays or failures in performance resulting from acts beyond its reasonable control including, without limitation, acts of God, acts of war or terrorism, pandemic or epidemic, shortage of supply, breakdowns or malfunctions. Considering the act of God events, you have the option either to reschedule the session or you will be entitled to obtain a refund.</p>
    <h4 className='info1'>Refund Eligibility:</h4>
    <p className='collect1'>Service Not Rendered: If the service was not provided or completed due to circumstances beyond the customer's control or due to an error on the part of the service provider, a refund may be warranted.</p>
    <p className='collect1'>Cancellation Before Service: If the customer cancels the service appointment within a reasonable timeframe before the scheduled service and meets any cancellation policy requirements, they may be eligible for a refund.</p>
    <p className='collect1'>Technical Issues or Service Interruptions: If there are technical issues or service interruptions that prevent the completion of the service, the customer may be eligible for a refund.</p>
    <p className='collect1'>Accidental Duplicate Service Bookings: If the customer accidentally books the same service multiple times and notifies the service provider promptly, they may be eligible for a refund for the duplicate booking.</p>
    <h4 className='info1'>Refund Process:</h4>
    <p className='collect1'>To request a refund for a service, customers can also contact our customer support team at support@honc.io with their details and reason for the refund request.
Refund requests will be reviewed and processed within 7-14 business days.
Approved refunds will be issued to the original payment method used for the service purchase.</p>
<h4 className='info1'>Modifications to the Refund Policy:</h4>
<p className='collect1'>honc reserves the right to modify or update this refund policy at any time. Any changes will be updated on our app or website.</p> 
        </Accordion.Body>


      </Accordion.Item>
      <Accordion.Item eventKey="6" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'  style={{color:"white"}} >Cancellation policy</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p className='collect1'> We understand that circumstances may arise where you need to cancel your booked service. Please review the following guidelines regarding cancellations:</p>
    <h4 className='info1'>One-Time Services:</h4>
    <p className='collect1'>For one-time services such as car washes or repairs, you may cancel your appointment up to 3 hours before the scheduled service time without incurring any cancellation fees.</p>
    <h4 className='info1'>Subscription Services:</h4>
    <p className='collect1'>You have the freedom to cancel your subscription plan at any time, directly through your account on our platform. There are no restrictions on when you can cancel your subscription during the billing cycle.</p>
    <p className='collect1'>If you choose to cancel your subscription before the end of the billing cycle, we will issue a prorated refund for the unused portion of your subscription period. This ensures that you only pay for the services you've received.</p>
    <h4 className='info1'>Refunds:</h4>
    <p className='collect1'>If the Customer is eligible for a refund for a cancelled order subject to the provisions of this T&Cs, the order value paid by the customer will be refunded to the customer's Honc Credit, unless the customer requests otherwise, and in the latter case, the refund will be made only to the original payment method used for making the order.</p>
    <p className='collect1'>It can take anywhere from 7 - 14 business days (or even up to 30 days for overseas banks) for your refund to be processed and reflected in your bank account, in consideration of the time and procedures required from Honc and/ or the Credit Card Company and/ or the Bank.</p>
        </Accordion.Body>


      </Accordion.Item>
    
    
 
   
      <Accordion.Item eventKey="7" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'  style={{color:"white"}} >Functionality</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p className='collect1'>The platform may be under constant upgrades, and some functions and features may not be fully operational. In case of any typographical errors, inaccuracies or omissions on our website or application related to products, services, promotions, offers and availability of products and/or services. We reserve the right to correct such errors.</p>
        </Accordion.Body>


      </Accordion.Item>
       
      <Accordion.Item eventKey="8" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'  style={{color:"white"}} >Limitation of Liability</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p className='collect1'>To the extent permitted by law, honc provides this Platform and content on an "as-is" and "as available" basis and we make no representation or warranty of any kind, express or implied, regarding the content or availability of this Platform, or that it will be timely or error-free, that defects will be corrected, or that the Platform or server that makes it available are free of viruses or other harmful components. Neither honc nor its directors, officers, agents, employees or contractors shall have any liability for any direct, indirect, special or consequential losses or damages (including without limitation, damages for loss of business or loss of profits), arising in contract, tort or otherwise from the use or inability to use this Platform. Nothing in these terms shall exclude or limit liability for death or personal injury resulting from our negligence or that of our agents or employees.</p>
        <p className='collect1'>Merchants are solely responsible for the preparation, condition and quality of goods sold to you. In cases of Merchant Delivery, Merchants are responsible for delivery of the goods and/or Orders. Honc shall not be responsible or liable for any loss, damage, expense, cost or fees arising from your contractual relationship with the Merchant.</p>
        </Accordion.Body>


      </Accordion.Item> 

      <Accordion.Item eventKey="9" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'  style={{color:"white"}} >Indemnity</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p className='collect1'>You agree to indemnify and hold honc, its parents, subsidiaries and affiliates, agents, officers, directors, or other employees harmless from any claim, demand, or damage (whether direct, indirect, or consequential), including reasonable attorneys' fees, made by anyone in connection with your use of the Platform, with your submissions, with any alleged infringement of intellectual property or other right of any person or entity relating to the Platform, your violation of these Terms, and any other acts or omissions relating to the Platform.</p>
        </Accordion.Body>


      </Accordion.Item>
      <Accordion.Item eventKey="10" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'  style={{color:"white"}} >Amendments</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p className='collect1'>honc may amend these Terms at any time in its sole discretion, and the amended Terms shall be effective immediately upon posting, subject to applicable laws. It is your responsibility to check the Terms regularly. If you do not agree with the amended Terms, whether in whole or in part, you must stop using the Platform and/or any of its services immediately.</p>
        </Accordion.Body>


      </Accordion.Item>
      <Accordion.Item eventKey="11" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'  style={{color:"white"}} >Disclaimer</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p className='collect1'>The information, content, products, services, and materials available through the application (whether provided by Honc , you, other users or other affiliates/third parties), including without limitation, goods and services orders, submissions, text, photos, graphics, audio files, video, and links, are provided "as is" and "as available" without warranties of any kind, either express or implied. To the maximum extent permitted by law, honc disclaims all representations and warranties, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, title, noninfringement, freedom from computer virus, and implied warranties arising from course of dealing or course of performance.   
    </p>
        </Accordion.Body>


      </Accordion.Item>
      <Accordion.Item eventKey="12" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'  style={{color:"white"}} >Contact us</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p className='collect1'>If you have any questions about these Terms, please contact us at  info@honc.io.
      {/* <span style={{ position: 'relative' }}>
 
  <span style={{
    position: 'absolute',
    bottom: '-4px', // adjust this value for desired margin
    left: 0,
    width: '100%',
    height: '1px', // thickness of the underline
    backgroundColor: 'white'
  }} />
</span> */}

For customer support, you may reach out to us via the Help & Support Center available on the Platform.
</p>
        </Accordion.Body>


      </Accordion.Item>
 
      </Accordion>
        </Container>
    </section>
  )
}

export default TermsandConditions