import React from 'react'
import { Row,Container, Col } from 'react-bootstrap'
import "../Styles/HomePage.css"
import { Link } from 'react-router-dom'
import { useState,useEffect } from 'react'
import LetsTalk from '../Modals/LetsTalk'


const Footer = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [currentYear] = useState(new Date().getFullYear());
    const closeModal = () => {
        setShowModal(false);
     // Refresh the page after closing the modal
      };
  
  return (
    <div  className=' overflow-hidden p-0' >
   <section  style={{backgroundColor:"#0D0D0D"}}>
    <Container >
        <Row className='footer-content' >
            {props?.isFromHomePage ?
           ( <Col lg={7} md={7} sm={6} xs={6}>
                <h5 className='honc-footer-contact d-flex' ><span style={{color:"#FFFFFF"}} >How can we help?</span><Link to ="/Contactus" target ="_blank"><span style={{color:"#219FF3",  textDecoration: "underline",textDecorationColor: "##219FF3"}} className='contact-us-footer'>Contact us</span></Link></h5>
            </Col>):(
                <Col lg={7} md={7} xs={7}>
                    
                </Col>
            )
}
            {/* <Col lg={5} md={5} sm={6} xs={6} className='d-flex  footer-social'>
                <div className='d-flex' style={{flexDirection:"row",flex:1,flexWrap:"wrap",justifyContent:"end"}}>
                    <div className='d-flex '  style={{justifyContent: "space-evenly", alignItems: "center", flex: 1}}>
                    <div>
                   <a href="https://twitter.com/letshonc" target="_blank"> <img src="./images/twitter.png"  className='twitter-img'/></a>
                    </div>
                    <div>
                   <a href="https://www.facebook.com/letshonc/" target="_blank"> <img src="./images/facebook.png" className='facebook-img' /></a>
                    </div>
                    <div>
                   <a href="https://www.linkedin.com/company/honc" target="_blank"> <img src="./images/linkedinn.png" className='linkedinn-img' /></a>
                    </div>
                    <div>
                   <a href="https://www.instagram.com/letshonc/?igshid=YmMyMTA2M2Y%3D" target="_blank"> <img src="./images/Instagram.png" className='Instagram-img'/></a>
                    </div>
                
                </div>
                </div>
            </Col> */}
        </Row>
        <Row style={{marginTop:"8%"}}>
            <Col lg={12} >
                <img src="./images/honc-footer.png" className='img-fluid' style={{}}/>
                <p style={{ color: "#FFFFFF" }} className='ravini'>
      ©{currentYear} Cultural Links Technology LLC.
    </p>
                
            </Col>
        </Row>
        <Row className='footer-contact-details'>
            <Col  className='honc-details col-lg-9 col-md-12 '>
              <div className='d-flex row' >
                 {/* <div style={{color:"#FFFFFF"}} className=' honc-contact-details contact-details col-lg-3 col-md-3 col-xs-12 col-sm-12'>
                  <Link to="/Download" target="_blank" style={{  textDecoration: "underline",
  textDecorationColor: "#FFFFFF", color:"#FFFFFF"}}> <span className='Contact-us'>Download App</span></Link>
                  </div> */}
                 <div style={{color:"#FFFFFF",textDecoration: "underline",
  textDecorationColor: "#FFFFFF",}} className='honc-contact-details col-lg-3 col-md-3 col-xs-12 col-sm-12'>
                   <Link to ="/Contactus" target="_blank" style={{textDecoration:"none",color:"#FFFFFF"}}> <span className='Contact-us'>Contact us</span></Link>
                  </div>
              
                 <div style={{color:"#FFFFFF",textDecoration: "underline",
  textDecorationColor: "#FFFFFF",}} className=' honc-contact-details col-lg-3 col-md-3 col-xs-12 col-sm-12' >
              <a href="/terms-of-use.html" target="_blank"  style={{textDecoration:"none",color:"#FFFFFF"}}> <span className='Contact-us'>Terms of use</span></a> 
                  </div>
                 <div style={{color:"#FFFFFF",textDecoration: "underline",
  textDecorationColor: "#FFFFFF",}} className=' honc-contact-details col-lg-3 col-md-3 col-xs-12 col-sm-12'>
                   <Link to="/privacy-policy.html" target="_blank" style={{textDecoration:"none", color:"#FFFFFF"}}><span className='Contact-us'>Privacy Policy </span></Link>
                  </div>
              </div>
            </Col>
            <Col  className='  col-lg-3 col-md-12-justify chat-btn  d-flex pe-4' >
              
                <div style={{display:"flex",backgroundColor:"#219FF3",borderRadius:"30px",justifyContent:"center",alignItems:"center",cursor:"pointer",color:"#FFFFFF",}} className='chat-with-us ' >
                    
                    <div className=''>
                        <p style={{textDecoration: "none"}} className="Chat-with-us  mt-3"  data-bs-toggle="modal" data-bs-target="#LetsTalkModal" onClick={() => {
                  setShowModal(true);
                }} >Let's talk</p>
                    </div>
                </div>
              
            </Col> 

        </Row>
    </Container>
   </section>
   <LetsTalk
          showModal={showModal}
          setShowModal={(val) => setShowModal(val)} closeModal={closeModal} />
   </div>
  )
}

export default Footer