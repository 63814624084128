import React, { useRef, useEffect, useState } from 'react';
import "../Styles/Styles2.css"
import { Container, Row, Col } from 'react-bootstrap';
import "../Styles/HomePage.css"
import Letstalk from './Lets talk';
import "../Styles/scroll2.css";

const  Car=()=>{
 
    const firstSectionRef = useRef(null);
    const secondSectionRef = useRef(null);
    const thirdSectionRef = useRef(null);
    const fourthSectionRef = useRef(null);
    const fifthSectionRef = useRef(null);
    
    const [isFirstSectionVisible, setIsFirstSectionVisible] = useState(true);
    const [isSecondSectionVisible, setIsSecondSectionVisible] = useState(true);
    const [isThirdSectionVisible, setIsThirdSectionVisible] = useState(true);
    const [isFourthSectionVisible, setIsFourthSectionVisible] = useState(true);
    const [isFifthSectionVisible, setIsFifthSectionVisible] = useState(true);
    const [isfromcarcare,setIsfromcarcare]=useState(false)
  
    const handledownload = () => {
      // window.open("https://www.onelink.to/honc","_blank")
      const userAgent = window.navigator.userAgent.toLowerCase();
  
      if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
  
        window.open("https://www.onelink.to/honc", "_blank");
      } else if (userAgent.includes('android')) {
  
        window.open("https://www.onelink.to/honc", "_blank");
      } else {
  
        window.open("https://play.google.com/store/apps/details?id=com.honc_customer&hl=en_IN", "_blank");
      }
    }
  
    // Function to handle scroll behavior
    const handleScroll = () => {
      if (firstSectionRef.current && secondSectionRef.current && thirdSectionRef.current && fourthSectionRef.current && fifthSectionRef.current) {
        const scrollPosition = window.scrollY;
        const firstSectionOffset = firstSectionRef.current.offsetTop;
        const secondSectionOffset = secondSectionRef.current.offsetTop;
        const thirdSectionOffset = thirdSectionRef.current.offsetTop;
        const fourthSectionOffset = fourthSectionRef.current.offsetTop;
        const fifthSectionOffset = fifthSectionRef.current.offsetTop;
         
  
        if (scrollPosition >= firstSectionOffset && scrollPosition < secondSectionOffset) {
          
          setIsFirstSectionVisible(true);
          setIsSecondSectionVisible(true);
          setIsThirdSectionVisible(true);
          setIsFourthSectionVisible(true);
          setIsFifthSectionVisible(true);
        } else if (scrollPosition >= secondSectionOffset && scrollPosition < thirdSectionOffset) {
          setIsFirstSectionVisible(false);
          setIsSecondSectionVisible(true);
          setIsThirdSectionVisible(true);
          setIsFourthSectionVisible(true);
          setIsFifthSectionVisible(true);
        } else if (scrollPosition >= thirdSectionOffset && scrollPosition < fourthSectionOffset) {
          setIsFirstSectionVisible(false);
          setIsSecondSectionVisible(false);
          setIsThirdSectionVisible(true);
          setIsFourthSectionVisible(true);
          setIsFifthSectionVisible(true);
        } else if (scrollPosition >= fourthSectionOffset && scrollPosition < fifthSectionOffset) {
          setIsFirstSectionVisible(false);
          setIsSecondSectionVisible(false);
          setIsThirdSectionVisible(false);
          setIsFourthSectionVisible(true);
          setIsFifthSectionVisible(true);
        } else {
          setIsFirstSectionVisible(false);
          setIsSecondSectionVisible(false);
          setIsThirdSectionVisible(false);
          setIsFourthSectionVisible(false);
          setIsFifthSectionVisible(true);
        }
      }
    };
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
   
 

  return (
    <>
<div id="container2">
  <div class="photobanner " >
    {/* <img src="https://i.stack.imgur.com/xckZy.jpg" alt=""/>
    <img src="https://i.stack.imgur.com/CVgbr.jpg" alt=""/>
    <img src="https://i.stack.imgur.com/7c4yC.jpg" alt=""/>
    <img src="https://i.stack.imgur.com/RTiml.jpg" alt=""
/><img src="https://i.stack.imgur.com/xckZy.jpg" alt=""/>
<img src="https://i.stack.imgur.com/CVgbr.jpg" alt=""/>
<img src="https://i.stack.imgur.com/7c4yC.jpg" alt=""/>
<img src="https://i.stack.imgur.com/RTiml.jpg" alt=""/> */}
          <a href="#Completecarwash" style={{textDecoration:"none"}}> <img src=  "./images/Carcare-Complete-car-wash.png" className='img2' /></a> 
          <a href="#DailyCarwash"  style={{textDecoration:"none"}}> <img src="./images/Carcare-Daily-car-wash.png"className='img2'   /></a>
         <a href="#Flattyrefix"  style={{textDecoration:"none"}}> <img src=  "./images/Carcare-Flat-tyre-fix.png"  className='img2' /></a> 
            <a href="#BatteryJumpstart" style={{textDecoration:"none"}}> <img src= "./images/BatteryJumpstart.png"className='img2'  /></a>
       <a href="#Carservicing" style={{textDecoration:"none"}}> <img src=   "./images/Carservicing.png"className='img2'  /></a> 
       <a href="#Completecarwash" style={{textDecoration:"none"}}> <img src=  "./images/Carcare-Complete-car-wash.png" className='img2' /></a> 
          <a href="#DailyCarwash" style={{textDecoration:"none"}}> <img src="./images/Carcare-Daily-car-wash.png"className='img2'   /></a>
         <a href="#Flattyrefix" style={{textDecoration:"none"}}>  <img src=  "./images/Carcare-Flat-tyre-fix.png"  className='img2' /></a> 
            <a href="#BatteryJumpstart" style={{textDecoration:"none"}}> <img src= "./images/BatteryJumpstart.png"className='img2'  /></a>
       <a href="#Carservicing" style={{textDecoration:"none"}}> <img src=   "./images/Carservicing.png"className='img2'  /></a> 
  </div>
</div>
<div className='app2 mt-5' style={{backgroundColor:"#0d0d0d"}} >
      <div  className={`first-section ${isFirstSectionVisible ? '' : 'hidden'} container`} id="DailyCarwash" >
        
      <Row className='d-flex service5'style={{   overflowX: "auto", padding: "10px" }} >
    <Col lg={6} md={6} xs={12} sm={12} style={{  flexDirection: "column" }} className='d-flex '>
      <div >
        <div>
      <div class="relative" style={{ translate: "none", rotate: "none", scale: "none", transform: "translate(0px, 0px)" }} className='services-car-wash'>
        <h1 style={{  color: '#74D077' }} className='services-heading'>Daily <br className='breaknone'></br>Car Wash</h1>
      </div>
      </div>
      <div className='mt-4 desktop-book-now' onClick={handledownload}>
          <Letstalk
          value="Book Now" />
          </div>
          </div>
          </Col>
      <Col lg={6} md={6} xs={12} sm={12}   >
        <div >
     
      <div >
        <div className='d-flex mt-4' style={{ flexDirection: "row" }}>
        <img src="./images/bullet-rightmark.png"  className='d-flex justify-content-center align-items-center mt-2 right-mark'/>
        <h1 style={{color:"#B7AA98"}} className='ms-4 daily-car-wash-desc' >The daily external vehicle cleaning happens at your parking lot only</h1>
      </div>
      <div className='d-flex mt-4' style={{flexDirection:"row"}}>
        <img src="./images/bullet-rightmark.png"  className='d-flex justify-content-center align-items-center mt-2 right-mark'/>
        <h1 style={{color:"#B7AA98"}} className='ms-4 daily-car-wash-desc' >The service timings are from 6am - 9am</h1>
      </div>
      <div className='d-flex mt-4' style={{flexDirection:"row"}}>
        <img src="./images/bullet-rightmark.png"  className='d-flex justify-content-center align-items-center mt-2 right-mark'/>
        <h1 style={{color:"#B7AA98"}} className='ms-4 daily-car-wash-desc' >Unlimited Free Car Spa is included in this package</h1>
      </div>
      <div className='d-flex mt-4' style={{flexDirection:"row"}}>
        <img src="./images/bullet-rightmark.png"  className='d-flex justify-content-center align-items-center mt-2 right-mark'/>
        <h1 style={{color:"#B7AA98"}} className='ms-4 daily-car-wash-desc mt-1' >Weekly one holiday</h1>
      </div>
     
      
      
     </div>
     <Row className='mt-4'>
        <div className='d-flex justify-content-start flex-start'>
            <img src="./images/Dailycleaning2.png" className='services-img2'/>
        </div>
     </Row>
     <div className='mt-4 mobile-book-now' onClick={handledownload}>
          <Letstalk
          value="Book Now" />
          </div>
     </div>
     </Col>
      </Row>
        {/* Content of the first section */}
      </div>

      <div ref={secondSectionRef} className={`second-section ${isSecondSectionVisible ? '' : 'hidden'} container-fluid`} id="Completecarwash">
        <div className='container'>
      <Row className='d-flex service5 ' style={{   overflowX: "auto", padding: "10px" }} >
    <Col lg={6} md={6} xs={12} sm={12} style={{  flexDirection: "column",position:"relative" }} className='d-flex '>
      <div  >
        <div > 
      <div class="relative" style={{ translate: "none", rotate: "none", scale: "none", transform: "translate(0px, 0px)" }} className='services-complete-car-wash'>
        <h1 style={{  color: '#74D077' }} className='services-heading'>Complete<br className='breaknone'></br>Car Wash</h1>
      </div>
      </div>
      <div className='mt-3 desktop-book-now' onClick={handledownload}>
          <Letstalk
          value="Book Now" />
          </div>
          </div>
          </Col>
      <Col lg={6} md={6} xs={12} sm={12}  >
     
      <div >
        <div className='d-flex mt-4' style={{ flexDirection: "row" }}>
        <img src="./images/bullet-rightmark.png"  className='d-flex justify-content-center align-items-center mt-2 right-mark' />
        <h1 style={{color:"#B7AA98"}} className='ms-4 daily-car-wash-desc' >Full Exterior body wash includes Front and Rear Bumper Cleaning, Windshield cleaning & Runboard Cleaning</h1>
      </div>
      <div className='d-flex mt-4' style={{flexDirection:"row"}}>
        <img src="./images/bullet-rightmark.png"  className='d-flex justify-content-center align-items-center mt-2 right-mark'/>
        <h1 style={{color:"#B7AA98"}} className='ms-4 daily-car-wash-desc' >Tyre and Rim Dressing</h1>
      </div> 
      <div className='d-flex mt-4' style={{flexDirection:"row"}}>
        <img src="./images/bullet-rightmark.png"  className='d-flex justify-content-center align-items-center mt-2 right-mark'/>
        <h1 style={{color:"#B7AA98"}} className='ms-4 daily-car-wash-desc' >Internal Car Cleaning, Cleaning of floor mats, Seat Cover Cleaning</h1>
      </div>
      
       <div className='d-flex mt-4' style={{flexDirection:"row"}}>
        <img src="./images/bullet-rightmark.png"  className='d-flex justify-content-center align-items-center mt-2 right-mark'/>
        <h1 style={{color:"#B7AA98"}} className='ms-4 daily-car-wash-desc' >Dashboard Dressing</h1>
      </div> 
     
      
     </div> 
    
        {/* <div className='d-flex justify-content-start flex-start'>
            <img src="./images/Dailycleaning2.png" style={{width:"50%"}}/>
        </div> */}
  {/* <video width="320" height="340"  autoplay> */}
  <div className="video-container" >
  <video  width="320" height="340" loop="true" autoplay="autoplay" id="vid"  muted  >
  <source src="./images/Cars_Video.mp4" type="video/mp4" />
</video>
</div>
<div className='mt-4 mobile-book-now' onClick={handledownload}>
          <Letstalk
          value="Book Now" />
          </div>

   
     </Col>
      </Row>
      </div>
        {/* Content of the second section */}
      </div>

      <div ref={thirdSectionRef} className="third-section" id="Flattyrefix">
      <div className='container'>
      <Row className='d-flex service5 ' style={{   overflowX: "auto", padding: "10px" }} >
    <Col lg={6} md={6} xs={12} sm={12} style={{  flexDirection: "column",position:"relative" }} className='d-flex '>
      <div  >
        <div > 
      <div class="relative" style={{ translate: "none", rotate: "none", scale: "none", transform: "translate(0px, 0px)" }} className='services-flat-tyre'>
        <h1 style={{  color: '#74D077' }} className='services-heading'>Flat-tyre<br className='breaknone'></br>fix</h1>
      </div>
      </div>
      <div className='mt-3 desktop-book-now' onClick={handledownload}>
          <Letstalk
          value="Book Now" />
          </div>
          </div>
          </Col>
      <Col lg={6} md={6} xs={12} sm={12}  >
   
      <div >
        <div className='d-flex mt-3' style={{ flexDirection: "row" }} >
        <img src="./images/bullet-rightmark.png"  className='d-flex justify-content-center align-items-center mt-2 right-mark'/>
        <h1 style={{color:"#B7AA98"}} className='ms-4 daily-car-wash-desc' >The daily external vehicle cleaning happens at your parking lot only</h1>
      </div>
      <div className='d-flex mt-4' style={{flexDirection:"row"}}>
        <img src="./images/bullet-rightmark.png"  className='d-flex justify-content-center align-items-center mt-2 right-mark'/>
        <h1 style={{color:"#B7AA98"}} className='ms-4 daily-car-wash-desc' >The service timings are from 6am - 9am</h1>
      </div>
      <div className='d-flex mt-4' style={{flexDirection:"row"}}>
        <img src="./images/bullet-rightmark.png"  className='d-flex justify-content-center align-items-center mt-2 right-mark'/>
        <h1 style={{color:"#B7AA98"}} className='ms-4 daily-car-wash-desc' >Unlimited Free Car Spa is included in this package</h1>
      </div>
        <div className='d-flex mt-4' style={{flexDirection:"row"}}>
        <img src="./images/bullet-rightmark.png"  className='d-flex justify-content-center align-items-center mt-2 right-mark'/>
        <h1 style={{color:"#B7AA98"}} className='ms-4 daily-car-wash-desc' >The service timings are from 6am - 9am</h1>
      </div>
      <div className='d-flex mt-4' style={{flexDirection:"row"}}>
        <img src="./images/bullet-rightmark.png"  className='d-flex justify-content-center align-items-center mt-2 right-mark'/>
        <h1 style={{color:"#B7AA98"}} className='ms-4 daily-car-wash-desc' >Unlimited Free Car Spa is included in this package</h1>
      </div>
      
      
      
     </div> 
     <Row className='pt-5'>
        <div className='d-flex justify-content-start flex-start'>
            <img src="./images/Dailycleaning2.png" className='services-img2'/>
        </div>
     </Row>
     <div className='mt-4 mobile-book-now' onClick={handledownload}>
          <Letstalk
          value="Book Now" />
          </div>
     </Col>
      </Row>
      </div>
        {/* Content of the third section */}
      </div>
      <div ref={fourthSectionRef} className={`fourth-section ${isFourthSectionVisible ? '' : 'hidden'}`} id="BatteryJumpstart">
      <div className='container'>
      <Row className='d-flex service5 ' style={{   overflowX: "auto", padding: "10px" }} >
    <Col lg={6} md={6} xs={12} sm={12} style={{  flexDirection: "column",position:"relative" }} className='d-flex '>
      <div  >
        <div > 
      <div class="relative" style={{ translate: "none", rotate: "none", scale: "none", transform: "translate(0px, 0px)" }} className=' services-BatteryJumpstart'>
        <h1 style={{  color: '#74D077' }} className='services-heading'>Battery <br className='breaknone'></br>Jumpstart</h1>
      </div>
      </div>
      <div className='mt-3 desktop-book-now' onClick={handledownload}>
          <Letstalk
          value="Book Now" />
          </div>
          </div>
          </Col>
      <Col lg={6} md={6} xs={12} sm={12}  >
   
      <div >
        <div className='d-flex mt-4' style={{ flexDirection: "row" }}>
        <img src="./images/bullet-rightmark.png"  className='d-flex justify-content-center align-items-center mt-2 right-mark'/>
        <h1 style={{color:"#B7AA98"}} className='ms-4 daily-car-wash-desc' >Verify battery’s Charging/ discharging status of the Car/ Bike</h1>
      </div>
      <div className='d-flex mt-4' style={{flexDirection:"row"}}>
        <img src="./images/bullet-rightmark.png"  className='d-flex justify-content-center align-items-center mt-2 right-mark'/>
        <h1 style={{color:"#B7AA98"}} className='ms-4 daily-car-wash-desc' >Recharge the battery using Jump cables</h1>
      </div>
      <div className='d-flex mt-4' style={{flexDirection:"row"}}>
        <img src="./images/bullet-rightmark.png"  className='d-flex justify-content-center align-items-center mt-2 right-mark'/>
        <h1 style={{color:"#B7AA98"}} className='ms-4 daily-car-wash-desc' >Recheck the Charging status and confirm the working condition</h1>
      </div>
      
      <div className='d-flex mt-4' style={{flexDirection:"row"}}>
        <img src="./images/bullet-rightmark.png"  className='d-flex justify-content-center align-items-center mt-2 right-mark'/>
        <h1 style={{color:"#B7AA98"}} className='ms-4 daily-car-wash-desc' >Any additional charges will be informed by the partner directly</h1>
      </div>
     
      
     </div> 
     <Row className='pt-5'>
        <div className='d-flex justify-content-start flex-start'>
            <img src="./images/Dailycleaning2.png" className='services-img2'/>
        </div>
     </Row>
     <div className='mt-4 mobile-book-now' onClick={handledownload}>
          <Letstalk
          value="Book Now" />
          </div>
     </Col>
      </Row>
      </div>
        {/* Content of the fourth section */}
      </div>

      <div ref={fifthSectionRef} className={`fifth-section ${isFifthSectionVisible ? '' : 'hidden'}`} id="Carservicing" >
      <div className='container'>
      <Row className='d-flex service5 ' style={{   overflowX: "auto", padding: "10px" }} >
    <Col lg={6} md={6} xs={12} sm={12} style={{  flexDirection: "column",position:"relative" }} className='d-flex '>
      <div  >
        <div > 
      <div class="relative" style={{ translate: "none", rotate: "none", scale: "none", transform: "translate(0px, 0px)" }} className='services-Car-servicing'>
        <h1 style={{  color: '#74D077' }} className='services-heading'>Car<br className='breaknone'></br> Servicing</h1>
      </div>
      </div>
      <div className='mt-3 desktop-book-now' onClick={handledownload}>
          <Letstalk
          value="Book Now" />
          </div>
          </div>
          </Col>
      <Col lg={6} md={6} xs={12} sm={12}  >
  
      <div >
        <div className='d-flex mt-4' style={{ flexDirection: "row" }}>
        <img src="./images/bullet-rightmark.png"  className='d-flex justify-content-center align-items-center mt-3 right-mark'/>
        <h1 style={{color:"#B7AA98"}} className='ms-4 daily-car-wash-desc' >Engine oil & Wiper fluid & Oil filter replacement</h1>
      </div>
      <div className='d-flex mt-4' style={{flexDirection:"row"}}>
        <img src="./images/bullet-rightmark.png"  className='d-flex justify-content-center align-items-center mt-3 right-mark'/>
        <h1 style={{color:"#B7AA98"}} className='ms-4 daily-car-wash-desc' >Air filter cleaning/ replacement</h1>
      </div>
      <div className='d-flex mt-4' style={{flexDirection:"row"}}>
        <img src="./images/bullet-rightmark.png"  className='d-flex justify-content-center align-items-center mt-3 right-mark'/>
        <h1 style={{color:"#B7AA98"}} className='ms-4 daily-car-wash-desc' >Battery water, Gear Oil, Coolant top up Heater/spark plugs, Fuel filter checking</h1>
      </div>
      
      <div className='d-flex mt-4' style={{flexDirection:"row"}}>
        <img src="./images/bullet-rightmark.png"  className='d-flex justify-content-center align-items-center mt-3 right-mark'/>
        <h1 style={{color:"#B7AA98"}} className='ms-4 daily-car-wash-desc' >Electrical systems, Power steering, brake fluid</h1>
      </div>
     
      
     </div> 
     <Row className='pt-5'>
        <div className='d-flex justify-content-start flex-start'>
            <img src="./images/Dailycleaning2.png" className='services-img2'/>
        </div>
     </Row>
     <div className='mt-4 mobile-book-now' onClick={handledownload}>
          <Letstalk
          value="Book Now" />
          </div>
     </Col>
      </Row>
      </div>
        {/* Content of the fifth section */}
      </div>
    </div>
    </>
  );
}

export default Car;
