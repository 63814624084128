import React, { useState } from 'react'
import Header from '../Components/Header'
import Applyjob from '../Components/Applyjob'
import Footer from '../Components/Footer'
import Applyjobform from '../Components/Applyjobform'


const ApplyNow = () => {
    const [showApplyJobForm,setShowApplyJobForm] = useState(false);
  return (
    <div>
        <Header/>
        {showApplyJobForm?
        <Applyjobform
        setShowApplyJobForm={setShowApplyJobForm}/>
        :
     <Applyjob
     setShowApplyJobForm={setShowApplyJobForm}
     />
        }
     <Footer/>
    </div>
  )
}

export default ApplyNow