import React from 'react'
import {Row,Col,Container} from "react-bootstrap"
import "../Styles/Download.css"
import "../Styles/HomePage.css"

const DownloadApp = () => {
  const handleplaystore=()=>
  {
    window.open("https://play.google.com/store/apps/details?id=com.honc_customer&hl=en_IN&pli=1","_blank")
  }
  const handleappstore=()=>
  {
    window.open("https://apps.apple.com/in/app/honc/id1520441184","_blank")
  }
  return (
    <section className='Background' >
<div className='overflow-hidden p-0'>
    <div className='container'>
    <Row className='d-flex justify-content-center scanner-row'  >
    <Col lg={6} md={6} style={{ position: "relative", display: "flex", flexDirection: "column", justifyContent: "center" }}   >
      <Row>
        <div className="circle-bg" style={{zIndex:1}}></div>
        <div className="circle-background"></div>
        </Row>
        
        <Row className='' style={{ display: "flex",zIndex:2 }}>

<Col className='d-flex'>
<div>
<img src="./images/Honc-Scanner.png" className='honc-scanner'/>
</div>
<div style={{display:"flex",flexDirection:"column",justifyContent:"end"}} className='ms-3'>
    <div >
      {/* <a href="https://play.google.com/store/apps/details?id=com.honc_customer&hl=en_IN&pli=1"> */}
    <img src="./images/Google Play.png" className='img-fluid' onClick={handleplaystore} style={{cursor:"pointer"}} /> 
    {/* </a>  */}
    </div>
    <div className='mt-3'>
      {/* <a href="https://apps.apple.com/in/app/honc/id1520441184"> */}
    <img src="./images/App Store.png" className='img-fluid' onClick={handleappstore} style={{cursor:"pointer"}}/> 
    {/* </a> */}
    </div>

</div>
</Col>


        </Row>
        
  
        <Row className='' style={{marginTop:"5%"}}>
            <span style={{ color: "#74d077" }} className='honc-vehicle'>Got a Car? Get honc on your mobile. </span>
            <p style={{ color: "#74d077" }} className='honc-vehicle1'>Scan the QR code and honcing!</p>
        </Row>
        <Row className='mt-1'>
            <p style={{color:"#B7AA98"}} className='honc-mobile-app'>The honc mobile app is the fastest and easiest way to honc all your vehicle needs.</p>
        </Row>
        <Row className='mt-2'>
          
                <Col className='d-flex'>
                <img src="./images/Get services.png" className='mt-1 services-icon'/>
                <p style={{color:"white"}} className='ms-2 services-text'>Get Services</p>
                </Col>
                <Col className='d-flex'>
          <img src="./images/Auto care.png" className='mt-1 services-icon' />
          <p style={{color:"white"}} className='ms-2 services-text'>Find Car Experts</p>
          </Col>
               
        </Row>
        {/* <Row className='mt-1'>
          
          <Col className='d-flex'>
          <img src="./images/Auto care.png" className='mt-1 services-icon' />
          <p style={{color:"white"}} className='ms-2 services-text'>Auto Care Experts</p>
          </Col>
          <Col className='d-flex'>
          <img src="./images/Meet new people.png" className='mt-1 services-icon'/>
          <p style={{color:"white"}} className='ms-2 services-text'>Meet new people</p>
          </Col>
         
  </Row> */}
      
    </Col>

    <Col lg={6} md={6} style={{padding:"3%"}} className='mt-0 d-flex mobile-mock-img'   >
<div className='mt-0 d-flex mobile-mock-img'>
<img src="./images/Mobile_Mock.png" className='mobile_mock'/>
</div>

    </Col>
</Row>
</div>
</div>
</section>
  )
}

export default DownloadApp