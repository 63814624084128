import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import SheSquad from '../Components/SheSquad'
import { Helmet } from 'react-helmet';
import Test from '../Components/Test';

const SheCrew = () => {
  return (
    <div>
      <Helmet>
        <title>SheCrew</title>
      
      </Helmet>
        <Header/>
        <SheSquad/>
        {/* <Test/> */}
        <Footer/>
    </div>
  )
}

export default SheCrew