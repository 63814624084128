import React from 'react'
import Header from '../Components/Header'
import JoinasaPartner from '../Components/JoinasaPartner'
import Footer from '../Components/Footer'
import { Helmet } from 'react-helmet';

const Beapartner = () => {
  return (
    <div>
        <Helmet>
        <title>Beapartner</title>
     
      
      </Helmet>
        <Header/>
        <JoinasaPartner/>
        <Footer/>
    </div>
  )
}

export default Beapartner