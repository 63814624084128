import React from 'react'
import { useLottie } from "lottie-react";
import groovyWalkAnimation from "./CarAnimation.json";
import "../Styles/HomePage.css"


const JoinasaPartner = () => {
    const options = {
        animationData: groovyWalkAnimation,
        loop: true
      };
    
      const { View } = useLottie(options);
  return (
    <div style={{backgroundColor:"#0D0D0D",display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center"}}>
        <div style={{width:"40%"}} className='d-flex justify-content-center align-items-center'>
            {View}
        </div>
        <h1 style={{color:"white"}} className='assembling'>Hold on... We are assembling!</h1>
    </div>
  )
}

export default JoinasaPartner