import React from 'react'
import Header from '../Components/Header'

import Footer from '../Components/Footer'
import Meethoncers from '../Components/Meethoncers'
import "../Styles/HomePage.css"
import { Helmet } from 'react-helmet';


const About = () => {
  return (
    <div >
      <Helmet>
        <title>Team</title>
   
      
      </Helmet>
        <Header/>
<Meethoncers/>
    </div>
  )
}

export default About