import React from 'react'
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';

// import BackButton1 from "../../public/images/email.png"
import {  useNavigate } from 'react-router-dom';
import "../Styles/HomePage.css"


const Privacypolicy = () => {
    const button5=useNavigate()
    const navigate5=()=>
  {
    button5('/')
  }
   
  return (


    <section style={{backgroundColor:"#0D0D0D"}}>
        
        <Container fluid className="p-5">
        <img src="./images/backbutton.png" onClick={navigate5} alt="" className='arrow-img' ></img>
     
            <h1 style={{color:"white",marginTop:"2%"}} className='privacypolicy2'>Privacy Policy</h1>
            <p style={{marginTop:"30px",color:"white"}} className='policy-para'>This Privacy Policy explains how information about you is collected, used and disclosed when you use our website located at om.honc.io , or the honc mobile application (all available by Cultural Links Technology LLC) or when you otherwise interact with us.</p>
            

<p style={{marginTop:"30px",color:"white"}}className='encourage' >We encourage you to read the Privacy Policy whenever using the honc platform or submitting any personal information to honc.</p>

<Accordion className='rounded-2' >
      <Accordion.Item eventKey="0" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header' style={{color:"white"}} >Information collection,use and sharing</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
            <div>
    <h4 className='info1'>Information you provide to us</h4>
    <p className='collect1'>We collect information you provide directly to us when you create an account, participate in any interactive features of the application, fill out a form, participate in a contest or promotion,<br></br> make a purchase, apply for a job, communicate with us via third party social media sites, request customer support or otherwise communicate with us. The types of information we may<br></br> collect include your name, email address, postal address, phone number, financial information (including credit or debit card number, expiration date and security code) and<br></br> any other information you choose to provide.</p>
    </div>
    <div>
    <h4 className='info1'>Information we collect about you when you access our site/application and from other sources</h4>
<p className='collect1'>When you access or use our site or application, we automatically collect information about you, including: login information, location information, usage and preference information<br></br> (cookies and other tracking technologies), transaction information, device information, stored information and files. Information received about you from third parties (for ex: social media <br></br> sites, vendors, partners, etc)</p>
    </div>
    <div>
    <h4 className='info1'>Use of Information</h4>
<p className='collect1'>We may use information about you for various purposes, including but not limited to: maintaining and improving services, deliver the services you request, process transactions, respond to<br></br> comments, questions and provide customer service, communicate, monitor and analyse trends.</p>
    </div>
    <div>
    <h4 className='info1'>Sharing of Information</h4>
<p className='collect1'>We may share information about you as follows or as otherwise with vendors, partners and other service providers who need access to carry out work on our behalf. We may share this<br></br> information with government agencies assisting us for crime prevention or investigation. We may disclose your personal information if we are required by law to do so or if you violate our<br></br> terms and conditions</p>
    </div>
        </Accordion.Body>


      </Accordion.Item>
      <Accordion.Item eventKey="1" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'  style={{color:"white"}} >
Protection of your personal information
</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p>Our website and mobile application has secure pages to collect user information and most critical data is stored in encrypted form. We use various technical and management practices to help protect the confidentiality, security and integrity of data stored on our system. Our servers use Secure Sockets Layer (SSL) and encryption technology that works with Netscape Navigator, Microsoft Internet Explorer, Firefox, Safari and Chrome.</p>
   
        </Accordion.Body>


      </Accordion.Item>
      <Accordion.Item eventKey="2" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'  style={{color:"white"}} >Payments</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p className='collect1'>When you place an order through honc, you are required to select a method of payment. honc is not interested in your debit/credit card information nor does it store any of your debit/credit information. For transactions with online debit/credit cards, we transmit your entire card information to the appropriate debit/credit card company in an encrypted format with globally accepted rules and applications during order processing. Upon your choice, we keep a part of your card information in an encrypted format, taking precaution to maintain physical, electronic and procedural safeguards over your credit card information.</p>
   
        </Accordion.Body>


      </Accordion.Item>
      {/* <Accordion.Item eventKey="3" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'  style={{color:"white"}} >your consent</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p className='collect1'>By using the honc platform, you agree and consent to the collection, transfer, use, storage, disclosure and sharing of your information. if you do not agree, please do not use or access<br></br> the honc platform.</p>
        </Accordion.Body>


      </Accordion.Item> */}
      <Accordion.Item eventKey="4" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'  style={{color:"white"}} >Marketing and Survey</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p  className='collect1'>We value opinions and comments from our Users and frequently conduct surveys, online, on the Application. participation in these surveys is entirely optional. Typically, the information received is aggregated, and used to make improvements to website, application, other sales channels, services and to develop appealing content, features and promotions for members based on the results of the surveys. We don't sell or rent your personal information to third parties for their marketing purposes without your explicit consent. We may combine your information with information we collect from other companies and use it to improve and personalise our services, content and advertising. If you do not want to receive marketing communication, simply communicate your preference to us via e-mail or change your relevant settings from my account link on the user main page.</p>
        </Accordion.Body>


      </Accordion.Item>
      <Accordion.Item eventKey="5" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className="privacy-header"  style={{color:"white"}} >How to delete your account        </h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p className='collect1'>At honc, we provide you with the option to delete your account using our mobile application. Please note that if you have any pending transactions or outstanding amounts due, you will not be able to delete your account. Please complete all pending transactions and/or payments to delete your account. To delete your your account using our mobile application, please follow these steps:</p>
      
        <ol className='collect1 ' style={{marginLeft:"8%"}}>
          <li>Open the honc mobile app on your device.</li>
          <li>Navigate to the 'Profile' menu.</li>
          <li>Select 'Help and Support.'</li>
          <li>Raise a ticket.</li>
          <li>Click on the delete your account option.</li>
          <li>Follow the on-screen instructions to confirm and complete the deletion process.</li>
        </ol>

        <p className='collect1'>Please note that deleting your account will also delete your personal data, however, we may be required to retain some of your information if we process it as part of our legal obligations, or in pursuit of our own (or a third party’s legitimate interests) such as the assertion of, or defense against, legal claims, preventing fraud or protecting ourselves or others against abusive behavior. Once your data is deleted from the mobile app, it will be permanently removed from our systems and cannot be recovered. If you have any questions, please contact our customer support.</p>
        </Accordion.Body>

      </Accordion.Item>
      {/* <Accordion.Item eventKey="6" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className="privacy-header"  style={{color:"white"}} >refund policy</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p className='collect1'>At honc, we aim to provide a satisfactory experience to all our customers who use our services. Service cancellation refund will be processed to the customer through their respective mode of payment of the service within 48hrs of cancellation request received.<br></br>

Please review the following policy regarding refunds:
</p>
<p className='header3 rounded-2'>Refund Eligibility:
</p>
<p className='collect1'>Service Not Rendered: If the service was not provided or completed due to circumstances beyond the customer's control or due to an error on the part of the service provider, a refund may be warranted.
</p>
<p className='collect1'>Cancellation Before Service: If the customer cancels the service appointment within a reasonable timeframe before the scheduled service and meets any cancellation policy requirements, they may be eligible for a refund.
</p>
<p className='collect1'>Technical Issues or Service Interruptions: If there are technical issues or service interruptions that prevent the completion of the service, the customer may be eligible for a refund.
</p>
<p className='collect1'>Accidental Duplicate Service Bookings: If the customer accidentally books the same service multiple times and notifies the service provider promptly, they may be eligible for a refund for the duplicate booking.
</p>
<p className='header3 rounded-2'>Refund Process: 
</p>
<p className='collect1'>To request a refund for a service, customers can also contact our customer support team at support@honc.io or +91 9949177071 with their details and reason for the refund request.<br></br>
Refund requests will be reviewed and processed within 7 business days.<br></br>
Approved refunds will be issued to the original payment method used for the service purchase.

</p>
<p className='header3 rounded-2'>Modifications to the Refund Policy:
</p>
<p className='collect1'>honc reserves the right to modify or update this refund policy at any time. Any changes will be communicated to subscribers via email or through our website.
</p>
        </Accordion.Body>


      </Accordion.Item> */}
   


      <Accordion.Item eventKey="7" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'style={{color:"white"}}>Cookies</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p className='collect1'>honc uses "cookies" to help you personalize your online experience. One of the primary purposes of cookies is to provide a convenience feature to save you time. If you choose to decline cookies, you may not be able to fully experience the interactive features of the honc platform.</p>
        </Accordion.Body>


      </Accordion.Item>
      <Accordion.Item eventKey="8" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className="privacy-header"  style={{color:"white"}} >Privacy Policy amendment</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p className='collect1'>honc may change this Privacy Policy at any time by publishing the new version on the website.
</p>






        </Accordion.Body>


      </Accordion.Item>
      <Accordion.Item eventKey="9" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'  style={{color:"white"}} >Contact us</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p className='collect1'>All comments, queries and requests relating to our use of your information are welcomed and should be addressed to info@honc.io</p>
        </Accordion.Body>


      </Accordion.Item>

      </Accordion>
        </Container>
    </section>
  )
}

export default Privacypolicy