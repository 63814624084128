import React,{useState} from 'react';
import { useLottie } from "lottie-react";
import groovyWalkAnimation from "./CarAnimation.json";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Carousel } from 'react-bootstrap';
import "../Styles/HomePage.css"
import "../Styles/Carcare.css"
import Carcarecarousel from './Carcarecarousel';
import Car from './Car';
import ReusableServices from './ReusableServices';
import ServicesList from './Scroll2';
import Scroll2 from './Scroll2';




const Honcservices = () => {
  const [touchStartTime, setTouchStartTime] = useState(null);
  const handleImageContextMenu = (event) => {
    event.preventDefault();
  };  
  const handleTouchStart = () => {
    setTouchStartTime(new Date().getTime());
  };

  const handleTouchEnd = () => {
    const touchEndTime = new Date().getTime();
    const touchDuration = touchEndTime - touchStartTime;

    if (touchDuration >= 1000) {
     
      console.log('Long press!');
    }
  };

  return (
    <div style={{backgroundColor:"black"}}>
          <div className='overflow-hidden p-0' >
  <section className='container' >

  <Row className='d-flex' style={{ paddingTop: "5%" }}>
      <Col lg={7} md={7} sm={12} xs={12} style={{ position: "relative" }}>
        <Row className='mt-2' style={{ display: "flex" }}>
          <div className="circle-bg" style={{ zIndex: 1 }}></div>
          <div className="circle-background" style={{ zIndex: 2 }}></div>
        </Row>
        <Row className='mt-4'>
          <img src="./images/CarCareline.png" alt="CarCareline" className='Carcareline1' />
        </Row>
        <Row className='mt-4'>
          <div>
            <h1 style={{ color: "#74D077" }} className='car-service-heading'>
              Car Service
            </h1>
            <div style={{display:"flex",justifyContent:"flex-end"}}>
              <img src="./images/CarCareline.png" alt="CarCareline" className='Carcareline2' />
            </div>
            <h1 style={{ color: "#74D077" }} className='car-service-heading1'>
              at Doorstep
            </h1>
          </div>
        </Row>
        <h1 className='carcare-automobile'>
        Automobile services in minutes
        </h1>
        <Row className='mt-4' style={{display:"flex",justifyContent:"flex-end"}}>
        <img src="./images/Carcareline2.png" alt="CarCareline" className='Carcareline1' />
        </Row>
      </Col>
      <Col lg={5} md={5} sm={12} xs={12}  style={{marginTop:"-4%"}}>
        <img src="./images/Carservices.png" className='carcarevehicle'  onContextMenu={handleImageContextMenu}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}/>
      </Col>
    </Row>

</section>
</div>
{/* <Carcarecarousel/> */}
{/* <Scroll2/> */}
<Car/>
{/* <ReusableServices/> */}
{/* <Scroll2/> */}
{/* <Car/> */}
{/* <ServicesList/> */}


         </div>
  )
}

export default Honcservices