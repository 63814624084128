import './App.css';
import Header from './Components/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import HomePage from './Components/HomePage';
import { BrowserRouter, Routes, Route,useLocation } from "react-router-dom";
import Carcare from './Components/Honcservices';
import { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Footer from './Components/Footer';
import Home from './Screens/Home';
import Contactus from './Screens/Contactus';
import Download from './Screens/Download';
import Privacypolicy from './Screens/Privacypolicy';
import TermsandConditions from './Screens/TermsandConditions';
import About from './Screens/About';
import SheCrew from './Screens/SheCrew';
import Carservices from './Screens/Carservices';
import { Helmet } from "react-helmet";

import Beapartner from './Screens/Beapartner';
import Redirection from './Components/Redirection';

import ApplyNow from './Screens/ApplyNow';
import Applyjobform from './Components/Applyjobform';







function App() {
  const { pathname } = useLocation();
  useEffect(()=>{
    window.scrollTo({top:0,behavior:"auto"});
  },[pathname])
  return (
    
    <div className="App" >

          
     
      <Helmet>
      <title>honc-
  
  the car app</title>
    <meta name="description" content="
    Get the best car care expert to your doorstep in minutes"/>
  <meta name="keywords" content="Car Cleaning at Gated communtieis,
  Best Car Wash,
  Car wash app,
  Car wash at Doorstep,
  Car wash in Hyderabad,
  Jump Start in Hyderabad,
  Flat Tyre in Hyderabad,
  Car Servicing in Hyderabad,
  Denting and Painting in Hyderabad,
  PPF Coating in Hyderabad,
  Car wash in Bengaluru,
Jump Start in Bengaluru,
Flat Tyre in Bengaluru,
Car wash in Bangalore,
Jump Start in Bangalore,
Flat Tyre in Bangalore,
Car wash in Alkapoor,
Car wash in Ameenpur,
Car wash in Attapur,
Car wash in Bachupally,
Car wash in Bandlaguda,
Car wash in Bandlaguda Jagir,
Car wash in Borabanda,
Car wash in Chanda Nagar,
Car wash in Electronic City - I,
Car wash in Electronic City - II,
Car wash in Financial District,
Car wash in Gachibowli,
Car wash in Gandipet,
Car wash in Gopanapally,
Car wash in Hafeezpet,
Car wash in Haralur,
Car wash in Hebbal Kempapura,
Car wash in Kalkere,
Car wash in Khajaguda,
Car wash in Kokapet,
Car wash in Kondapur,
Car wash in Kothaguda,
Car wash in Kukatpally,
Car wash in Madhapur,
Car wash in Mallapur,
Car wash in Manchirevula,
Car wash in Manikonda,
Car wash in Masjid Banda,
Car wash in Miyapur,
Car wash in Nacharam,
Car wash in Nallagandla,
Car wash in Nanakramguda,
Car wash in Narsingi,
Car wash in Neknampur,
Car wash in Nizampet,
Car wash in Peeramcheri (TSPA),
Car wash in Peeramcheru,
Car wash in Puppalaguda,
Car wash in Rajendranagar,
Car wash in Shamshabad,
Car wash in Tellapur,
Jump Start in Alkapoor,
Jump Start in Ameenpur,
Jump Start in Attapur,
Jump Start in Bachupally,
Jump Start in Bandlaguda,
Jump Start in Bandlaguda Jagir,
Jump Start in Borabanda,
Jump Start in Chanda Nagar,
Jump Start in Electronic City - I,
Jump Start in Electronic City - II,
Jump Start in Financial District,
Jump Start in Gachibowli,
Jump Start in Gandipet,
Jump Start in Gopanapally,
Jump Start in Hafeezpet,
Jump Start in Haralur,
Jump Start in Hebbal Kempapura,
Jump Start in Kalkere,
Jump Start in Khajaguda,
Jump Start in Kokapet,
Jump Start in Kondapur,
Jump Start in Kothaguda,
Jump Start in Kukatpally,
Jump Start in Madhapur,
Jump Start in Mallapur,
Jump Start in Manchirevula,
Jump Start in Manikonda,
Jump Start in Masjid Banda,
Jump Start in Miyapur,
Jump Start in Nacharam,
Jump Start in Nallagandla,
Jump Start in Nanakramguda,
Jump Start in Narsingi,
Jump Start in Neknampur,
Jump Start in Nizampet,
Jump Start in Peeramcheri (TSPA),
Jump Start in Peeramcheru,
Jump Start in Puppalaguda,
Jump Start in Rajendranagar,
Jump Start in Shamshabad,
Jump Start in Tellapur,
Flat Tyre in Alkapoor,
Flat Tyre in Ameenpur,
Flat Tyre in Attapur,
Flat Tyre in Bachupally,
Flat Tyre in Bandlaguda,
Flat Tyre in Bandlaguda Jagir,
Flat Tyre in Borabanda,
Flat Tyre in Chanda Nagar,
Flat Tyre in Electronic City - I,
Flat Tyre in Electronic City - II,
Flat Tyre in Financial District,
Flat Tyre in Gachibowli,
Flat Tyre in Gandipet,
Flat Tyre in Gopanapally,
Flat Tyre in Hafeezpet,
Flat Tyre in Haralur,
Flat Tyre in Hebbal Kempapura,
Flat Tyre in Kalkere,
Flat Tyre in Khajaguda,
Flat Tyre in Kokapet,
Flat Tyre in Kondapur,
Flat Tyre in Kothaguda,
Flat Tyre in Kukatpally,
Flat Tyre in Madhapur,
Flat Tyre in Mallapur,
Flat Tyre in Manchirevula,
Flat Tyre in Manikonda,
Flat Tyre in Masjid Banda,
Flat Tyre in Miyapur,
Flat Tyre in Nacharam,
Flat Tyre in Nallagandla,
Flat Tyre in Nanakramguda,
Flat Tyre in Narsingi,
Flat Tyre in Neknampur,
Flat Tyre in Nizampet,
Flat Tyre in Peeramcheri (TSPA),
Flat Tyre in Peeramcheru,
Flat Tyre in Puppalaguda,
Flat Tyre in Rajendranagar,
Flat Tyre in Shamshabad,
Flat Tyre in Tellapur,
Car Servicing  in Alkapoor,
Car Servicing  in Ameenpur,
Car Servicing  in Attapur,
Car Servicing  in Bachupally,
Car Servicing  in Bandlaguda,
Car Servicing  in Bandlaguda Jagir,
Car Servicing  in Borabanda,
Car Servicing  in Chanda Nagar,
Car Servicing  in Electronic City - I,
Car Servicing  in Electronic City - II,
Car Servicing  in Financial District,
Car Servicing  in Gachibowli,
Car Servicing  in Gandipet,
Car Servicing  in Gopanapally,
Car Servicing  in Hafeezpet,
Car Servicing  in Haralur,
Car Servicing  in Hebbal Kempapura,
Car Servicing  in Kalkere,
Car Servicing  in Khajaguda,
Car Servicing  in Kokapet,
Car Servicing  in Kondapur,
Car Servicing  in Kothaguda,
Car Servicing  in Kukatpally,
Car Servicing  in Madhapur,
Car Servicing  in Mallapur,
Car Servicing  in Manchirevula,
Car Servicing  in Manikonda,
Car Servicing  in Masjid Banda,
Car Servicing  in Miyapur,
Car Servicing  in Nacharam,
Car Servicing  in Nallagandla,
Car Servicing  in Nanakramguda,
Car Servicing  in Narsingi,
Car Servicing  in Neknampur,
Car Servicing  in Nizampet,
Car Servicing  in Peeramcheri (TSPA),
Car Servicing  in Peeramcheru,
Car Servicing  in Puppalaguda,
Car Servicing  in Rajendranagar,
Car Servicing  in Shamshabad,
Car Servicing  in Tellapur,
Denting & Painting  in Alkapoor,
Denting & Painting  in Ameenpur,
Denting & Painting  in Attapur,
Denting & Painting  in Bachupally,
Denting & Painting  in Bandlaguda,
Denting & Painting  in Bandlaguda Jagir,
Denting & Painting  in Borabanda,
Denting & Painting  in Chanda Nagar,
Denting & Painting  in Electronic City - I,
Denting & Painting  in Electronic City - II,
Denting & Painting  in Financial District,
Denting & Painting  in Gachibowli,
Denting & Painting  in Gandipet,
Denting & Painting  in Gopanapally,
Denting & Painting  in Hafeezpet,
Denting & Painting  in Haralur,
Denting & Painting  in Hebbal Kempapura,
Denting & Painting  in Kalkere,
Denting & Painting  in Khajaguda,
Denting & Painting  in Kokapet,
Denting & Painting  in Kondapur,
Denting & Painting  in Kothaguda,
Denting & Painting  in Kukatpally,
Denting & Painting  in Madhapur,
Denting & Painting  in Mallapur,
Denting & Painting  in Manchirevula,
Denting & Painting  in Manikonda,
Denting & Painting  in Masjid Banda,
Denting & Painting  in Miyapur,
Denting & Painting  in Nacharam,
Denting & Painting  in Nallagandla,
Denting & Painting  in Nanakramguda,
Denting & Painting  in Narsingi,
Denting & Painting  in Neknampur,
Denting & Painting  in Nizampet,
Denting & Painting  in Peeramcheri (TSPA),
Denting & Painting  in Peeramcheru,
Denting & Painting  in Puppalaguda,
Denting & Painting  in Rajendranagar,
Denting & Painting  in Shamshabad,
Denting & Painting  in Tellapur,
PPF Coating in Alkapoor,
PPF Coating in Ameenpur,
PPF Coating in Attapur,
PPF Coating in Bachupally,
PPF Coating in Bandlaguda,
PPF Coating in Bandlaguda Jagir,
PPF Coating in Borabanda,
PPF Coating in Chanda Nagar,
PPF Coating in Electronic City - I,
PPF Coating in Electronic City - II,
PPF Coating in Financial District,
PPF Coating in Gachibowli,
PPF Coating in Gandipet,
PPF Coating in Gopanapally,
PPF Coating in Hafeezpet,
PPF Coating in Haralur,
PPF Coating in Hebbal Kempapura,
PPF Coating in Kalkere,
PPF Coating in Khajaguda,
PPF Coating in Kokapet,
PPF Coating in Kondapur,
PPF Coating in Kothaguda,
PPF Coating in Kukatpally,
PPF Coating in Madhapur,
PPF Coating in Mallapur,
PPF Coating in Manchirevula,
PPF Coating in Manikonda,
PPF Coating in Masjid Banda,
PPF Coating in Miyapur,
PPF Coating in Nacharam,
PPF Coating in Nallagandla,
PPF Coating in Nanakramguda,
PPF Coating in Narsingi,
PPF Coating in Neknampur,
PPF Coating in Nizampet,
PPF Coating in Peeramcheri (TSPA),
PPF Coating in Peeramcheru,
PPF Coating in Puppalaguda,
PPF Coating in Rajendranagar,
PPF Coating in Shamshabad,
PPF Coating in Tellapur,
Car cleaner on demand,
Car cleaning at the doorstep,
Car wash in India,
Car wash near me,
Cheapest Car wash in India,
Cheapest Car wash near me"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
  <meta name="description" content="
  Get the best car care expert to your doorstep in minutes"></meta>
      </Helmet>

        <Routes>
     
       
          <Route path="/" element={<Home/>}>  </Route>
       
          <Route path ="/contactus" element ={<Contactus/>}></Route>
          <Route path ="/download" element ={<Download/>}></Route>
          <Route path ="/privacy-policy.html" element ={<Privacypolicy/>}></Route>
          <Route path ="/terms-of-use.html" element ={<TermsandConditions/>}></Route>
          <Route path="/team" element ={<About/>}></Route>
          <Route path="/shecrew" element ={<SheCrew/>}></Route>
          <Route path="/car-care" element={<Carservices/>}></Route>
          <Route path="/be-a-partner" element={<Beapartner/>}></Route>
          <Route path="/redirection.html" element={<Redirection/>}></Route>
          <Route path="/ArjN" element={<Redirection/>}></Route>
          <Route path="/applyNow" element={<ApplyNow/>}></Route>
          <Route path="/applyjobform" element ={<Applyjobform/>}></Route>
   
        
        </Routes>
        {/* <Metadata/> */}
   
      

    </div>
  );
}

export default App;
