import React from 'react'
import { useEffect } from 'react';
import { useLottie } from "lottie-react";
import groovyWalkAnimation  from "../Components/Redirectionlottie.json"
import anotherAnimation from "../Components/Lotties.json"



const Redirection = () => {
    useEffect(()=>
    {
        redirection()
    },[])
    function redirection() {
        const params = new URLSearchParams(window.location.search)
        if (params.has('page')) {
            document.getElementById('garage-anchor').href = `honc://${params.get('page')}/${params.get('dlscreen')}/${params.get('dlkey')}/${params.get('dlparam')}`;
            setTimeout(() => {
                document.getElementById('redirectContainer').style.display = 'none';
                document.getElementById('downloadContainer').style.display = 'block';
                
            }, 5000);
            document.getElementById("garage-anchor").click();
        }
        else {
            alert("Wrong Url.");
        }
    }
    const options = {
        animationData: groovyWalkAnimation,
        loop: true
        
      };
    
      const { View } = useLottie(options);
      const anotherAnimationOptions = {
        animationData: anotherAnimation,
        loop: true

    };
    const { View: anotherAnimationView } = useLottie(anotherAnimationOptions);
  return (
    <div>
       <a id="garage-anchor" href=""></a>
       <div className="d-flex flex-column align-items-center" style={{ marginTop: '20%' }}>
      <img src="/images/logo.svg" width="30%" style={{ padding: '10px', backgroundColor: '#000' }} />
        <h4 style={{
          marginTop:'6px'
        }}>The Car App</h4>
      <div id="redirectContainer" style={{ display: 'block' }}>
        <div className="d-flex flex-column align-items-center" style={{ marginTop: '6vh',width:"200px",height:"200px" }}>
    {/* {View} */}
          <h1 style={{ fontSize: '28px', marginTop:'20vh' }}>Redirecting...</h1>
        </div>
      </div>
      <div className="mt-5" id="downloadContainer" style={{display: 'none'}}>
        <h2 className="text-center" style={{fontSize: '26px'}}>Uh-Oh!😔<br/><span style={{
          fontSize:'22px'
        }}>Looks like you don't have our app.</span></h2>
        {/* <div className="d-flex justify-content-center" style={{marginTop: '5vh',height:'18vh'}}>
        {anotherAnimationView}
        </div> */}
        <div style={{marginTop: '14vh', marginBottom: '10vh'}}>
          <h2 className="text-center mt-5" >No worries, just</h2>
          <h1 className="text-center" style={{fontSize: '22px',marginTop:'20px'}}>Download App</h1>
          <div className="row mt-3 mx-3" style={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center', marginTop:'10px'
          }}>
            <div className="col-5">
              <a href="https://play.google.com/store/apps/details?id=com.honc_customer">
                <img src="./images/Download/app-store_google-play-02.png" width="100%" />
              </a>
            </div>
            <div className="col-5">
              <a href="https://apps.apple.com/in/app/honc/id1520441184">
                <img src="./images/Download/app-store_google-play-03.png" width="100%" />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="mt-5" id="downloadContainer" style={{ display: 'none' }}>
        <h2 className="text-center" style={{ fontSize: '42px' }}>Looks like you don't have our app.</h2>
        <div className="d-flex justify-content-center" style={{ marginTop: '10vh',width:"300px",height:"300px" }}>
          {anotherAnimationView}
        </div>
        <div style={{ marginTop: '20vh', marginBottom: '10vh' }}>
          <h1 className="text-center mt-5 text-muted" style={{ fontSize: '48px' }}>Download App</h1>
          <div className="row mt-5">
            <div className="col-md-6">
              <a href="https://play.google.com/store/apps/details?id=com.honc_customer">
                <img src="./images/Download/app-store_google-play-02.png" width="100%" alt="Google Play" />
              </a>
            </div>
            <div className="col-md-6">
              <a href="https://apps.apple.com/in/app/honc/id1520441184">
                <img src="./images/Download/app-store_google-play-03.png" width="20%" alt="App Store" />
              </a>
            </div>
          </div>
        </div>
      </div> */}
    </div> 
    </div>
  )
}

export default Redirection