import React from 'react'
import Header from '../Components/Header'
import HomePage from '../Components/HomePage'
import Footer from '../Components/Footer'
import { Helmet } from 'react-helmet';

const Home = () => {
  
  return (
    <div>
        <Helmet>
        <title>Home</title>
        <meta name="description" content="Get the best car care expert to your doorstep in minutes. 
honc brings Car wash, Jump Start, Puncture Man, Servicing and many more to the doorstep."/>
      
      </Helmet>
  <Header/>
  <HomePage/>
  <Footer isFromHomePage={true} />
  </div>
  )
}

export default Home