import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "../Styles/HomePage.css";
import { useLottie } from "lottie-react";

import groovyWalkAnimation from "../Components/business.json"

const Reuse = (props) => {
    const options = {
        animationData: groovyWalkAnimation,
        loop: true,
        speed: 0.5

    };
    const { View } = useLottie(options);
  return (
 <section className='container grow-business'>
    <div>
        <Row style={{marginTop:"4%"}} className='d-flex justify-content-center' >
            <Col lg={6} md={6}  sm={12} xs={12} style={{paddingTop:"3%"}}>
                <h2 className='honc-heading' style={{color:"#B7AA98"}}>{props?.heading}</h2>
                <h2 className='honc-heading'style={{lineHeight:1, color: "#B7AA98"}}>{props?.heading2}</h2>
                <Row className='mt-4'>
                <h6 style={{color: "#219FF3", opacity: "1"}}className='journey'>{props?.title1}</h6>
                <h6 style={{color: "#219FF3", opacity: "1"}}className='journey'>{props?.title2}</h6>
                </Row>
                <Row className='mt-3'>
                <p style={{ lineHeight: 1.4, color: "#B7AA98" }} className='description'>honc is largest marketplace that enables brands and auto-care experts to reach more customers and engage with them through technology.</p>
                </Row>
            </Col>
            <Col lg={6} md={6}  sm={12} xs={12} style={{position:"relative"}} className='d-flex justify-content-end' >
                
                {/* <div className='backgroundimage' ></div>
              
                <img src="./images/shecrew.png"  className='she-crew-img' /> */}
                {View}
               
            </Col>

        </Row>
        </div>
        </section>
   
  )
}

export default Reuse