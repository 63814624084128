import React from 'react'
import Header from '../Components/Header'
import DownloadApp from '../Components/DownloadApp'
import ContactFooter from '../Components/ContactFooter'
import Footer from '../Components/Footer'
import { Helmet } from 'react-helmet';

const Download = () => {
  return (
    <div>
         <Helmet>
        <title>Download </title>
        <meta name="keywords" content="Download Car wash app,
Download Bike wash app,
Download car care app,
Download Bike care app, 
Download Car cleaning app,
Download Wash cleaning app,
Download Bike cleaning app"
></meta>
      
      </Helmet>
        <Header/>
        <DownloadApp/>
   <Footer/>
            </div>
  )
}

export default Download