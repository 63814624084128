import React from 'react'
import { useNavigate } from 'react-router-dom'
import "../Styles/HomePage.css"
import "../Styles/Applyjobform.css"
import Header from './Header'
import Footer from './Footer'
import { useState } from 'react'
import axios from "axios";
import Swal from 'sweetalert2'

const Applyjobform = (props) => {
    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        email: '',
      
    });
    const button=useNavigate()
    const navigatetojobdesc=()=>
    {
     props?.setShowApplyJobForm(false)
    
    }
    const [submitting, setSubmitting] = useState(false);
    const emailBody = `
    <div style="background-color: #f5f5f5; padding: 20px; font-family: Arial, sans-serif;">
        <h4 style="color: #333; font-size: 18px; margin-bottom: 10px;">Apply Job Form Response</h4>
     
   
      
        <div style="margin-bottom: 10px;">
            <strong style="color: #555;">Name:</strong> ${formData.name}
        </div>
        <div style="margin-bottom: 10px;">
            <strong style="color: #555;">Phone:</strong> ${formData.mobile}
        </div>
        <div style="margin-bottom: 10px;">
            <strong style="color: #555;">Email:</strong> ${formData.email}
        </div>
     

      
    </div>
    


`;
const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
};

const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitting(true);
  ////support@honc.io
    axios
      .post('https://api.honc.io/admin/chatbot/send-email', {
        to: 'support@honc.io',
        subject: 'Apply job form response from website',
      
        body: emailBody,
      })
      .then((response) => {
        console.log('Email sent successfully:', response.data);

        Swal.fire('Success', 'Your response has been submitted successfully', 'success');
    
        setFormData({
       
          name: '',
          mobile: '',
     email: '',
   
        });
        setSubmitting(false);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        setSubmitting(false);
      });
  };

  return (
    
    <section className='Background' >

    <div  className=' overflow-hidden p-0' >
        <div className='container'>
    <img src="./images/backbutton.png" onClick={navigatetojobdesc} alt="" className='arrow-img' style={{marginTop:"3%"}} ></img>
    </div>
        {/* <section className='d-flex justify-content-center align-items-center'style={{height:"100vh",margin:0}} >

        <div className='d-flex align-items-center'  style={{flexDirection:"column"}}>
            
                  <h2 className='apply-job-text d-flex justify-content-center'>Apply for a job</h2>
                  <div className='d-flex justify-content-center align-items-center'>
                  <div className='bottom d-flex justify-content-center align-items-center'></div>
                  </div>
                  <form className='d-flex justify-content-center align-items-center' style={{flexDirection:"column"}} onSubmit={handleSubmit} type="post">
  <div class="form-group email-grp d-flex justify-content-center">

    <input type="text" className="form-control name-form" id="exampleInputEmail1" aria-describedby="emailHelp" name="name" value={formData.name}
                                            onChange={handleInputChange} placeholder="enter name" required/>

  </div>
  <div class="form-group email-grp d-flex ">
  <input type="tel" class="form-control mobile" id="exampleInputPassword1" name="mobile" 
  value={formData.mobile}
  onChange={e => {
    const numericValue = e.target.value.replace(/\D/g, '');
    handleInputChange({ target: { name: 'mobile', value: numericValue } });
  }} placeholder="enter phone number" required/>
    <input type="email" class="form-control email" id="exampleInputEmail1" aria-describedby="emailHelp" name="email" value={formData.email}
                                                onChange={handleInputChange} placeholder="enter email id" required/>

  </div>
<div className='email-grp   justify-content-center'>
  
  <button type="submit" class="btn btn-primary " value={submitting ? 'Submitting...' : 'Submit'}>Submit</button>

  </div>
</form>
                </div>
         
            </section> */}
            </div>
         
   <div className='d-flex justify-content-center align-items-center ' style={{height:"80vh",margin:0}}>
  <div className='d-flex align-items-center'  style={{flexDirection:"column"}}>
  <form  style={{flexDirection:"column"}} onSubmit={handleSubmit} method="post">
  <h2 className='apply-job-text text-center' >Apply for a job</h2>
  <div className='d-flex justify-content-center'>
  <div className='bottom d-flex justify-content-center align-items-center'></div>
  </div>
 

  <div class="form-group  d-flex name-text">

    <input type="text" className="form-control name-form" id="exampleInputEmail1" aria-describedby="emailHelp" name="name" value={formData.name}
                                            onChange={handleInputChange} placeholder="enter name" required/>

  </div>
  <div class="form-group email-grp  form-grp2 d-flex ">
  <input type="tel" class="form-control mobile" id="exampleInputPassword1" name="mobile" 
  value={formData.mobile}
  onChange={e => {
    const numericValue = e.target.value.replace(/\D/g, '');
    handleInputChange({ target: { name: 'mobile', value: numericValue } });
  }} placeholder="enter phone number" required/>
    <input type="email" class="form-control email" id="exampleInputEmail1" aria-describedby="emailHelp" name="email" value={formData.email}
                                                onChange={handleInputChange} placeholder="enter email id" required/>

  </div>
  <div className='email-grp   ' style={{alignSelf:"start"}}>
  
  <button type="submit" class="btn btn-lg apply-submit-btn" value={submitting ? 'Submitting...' : 'Submit'} style={{backgroundColor: "#219FF3",color:"white"}}>Submit</button>

  </div>
    </form>
    </div>
  
   </div>
   </section>
            
          
  )
}

export default Applyjobform