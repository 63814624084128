import React from 'react'
import Contact from '../Components/Contact'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import ContactFooter from '../Components/ContactFooter'
import { Helmet } from 'react-helmet';

const Contactus = () => {
  return (
    <div>
       <Helmet>
        <title>Contact us</title>
     
     
        <meta name="keywords" content="Contact honc,
Contact honc team,
Contact honc support"/>
      
      </Helmet>
        <Header/>
        <Contact/>
    <Footer/>
    </div>
  )
}

export default Contactus